import React, { useContext, useEffect, useState } from "react";
import Product from "../../components/Product";
import Loading from "../../components/Loading";
import { ProductContext } from "../../contexts/ProductContext";
import axios from "axios";
import MetaDescTag from "../../components/MetaDescTag";
import { FaTrashAlt } from "react-icons/fa";
import { LuSettings2 } from "react-icons/lu";

const Products = () => {
  const {
    allProducts,
    loading,
    currentPage,
    setCurrentPage,
    totalPages,
    selectedType,
    selectedFilters,
    setSelectedFilters,
    isZipper,
    setIsZipper,
    isLockable,
    setIsLockable,
    categoryFilter,
    setCategoryFilter,
    isLastPage,
    fetchAllProducts,
    sizeFilter,
    setSizeFilter,
    materialFilter,
    setMaterialFilter, // Use materialFilter and setMaterialFilter
    priceRange,
    setPriceRange,
  } = useContext(ProductContext);

  const [filterType, setFilterType] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const sizeDataForCheckBox = [
    { id: 1, title: "Small" },
    { id: 2, title: "Medium" },
    { id: 3, title: "Large" },
  ];
  const materialDataForBox = [
    { id: 1, title: "Leather" },
    { id: 2, title: "Fabric" },
    { id: 3, title: "Glass" },
    { id: 4, title: "Velvet" },
  ];

  // Fetch filter categories from the API
  const fetchFilterType = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
        { prodType: null } // Make sure you're passing the correct prodType value
      );

      if (response.data && response.data.option) {
        setFilterType(response.data.option);
      } else {
        console.log("No filter options found");
      }

      setLoadingFilters(false);
    } catch (error) {
      console.error("Error fetching filter categories:", error);
      setLoadingFilters(false);
    }
  };

  // Reset the current page and fetch filter types when the component mounts
  useEffect(() => {
    fetchFilterType();
  }, []);

  const handleSizeChange = (e) => {
    const { value, checked } = e.target;
    setSizeFilter((prev) =>
      checked ? [...prev, value] : prev.filter((size) => size !== value)
    );
  };

  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((filter) => filter !== value)
    );
  };

  const handleMaterialChange = (e) => {
    const { value } = e.target;
    setMaterialFilter(value); // Update the material filter state
  };

  const handleZipperChange = (e) => {
    setIsZipper("1");
    setIsLockable(""); // Reset lockable when zipper is selected
  };

  const handleLockableChange = (e) => {
    setIsLockable("1");
    setIsZipper(""); // Reset zipper when lockable is selected
  };

  const handleCategoryFilterChange = (e) => {
    const { value, checked } = e.target;
    setCategoryFilter((prev) =>
      checked ? [...prev, value] : prev.filter((cat) => cat !== value)
    );
  };

  const handlePriceChange = (e) => {
    const value = Number(e.target.value);
    const name = e.target.className;

    // Prevent negative values
    if (value < 0) {
      return; // Prevent further action if negative
    }

    // Update price range based on which input was changed
    if (name.includes("min-range")) {
      setPriceRange([value, priceRange[1]]);
    } else if (name.includes("max-range")) {
      setPriceRange([priceRange[0], value]);
    }
  };

  useEffect(() => {
    const minSlider = document.querySelector(".min-range");
    const maxSlider = document.querySelector(".max-range");
    const priceSlider = document.querySelector(".price-slider");

    const minValue = priceRange[0];
    const maxValue = priceRange[1];
    const range = maxSlider.max - minSlider.min;
    const minPercent = ((minValue - minSlider.min) / range) * 100;
    const maxPercent = ((maxValue - minSlider.min) / range) * 100;

    priceSlider.style.left = `${minPercent}%`;
    priceSlider.style.right = `${100 - maxPercent}%`;
  }, [priceRange]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const clearFilters = () => {
    // Check if any filter is applied
    const isAnyFilterApplied =
      isZipper !== "" ||
      isLockable !== "" ||
      selectedFilters.length > 0 ||
      categoryFilter.length > 0 ||
      priceRange[0] !== 0 ||
      priceRange[1] !== 1000 ||
      sizeFilter.length > 0 ||
      materialFilter !== "";

    // If no filter is applied, do nothing
    if (!isAnyFilterApplied) {
      return;
    }

    // Reset the state variables
    setIsZipper("");
    setIsLockable("");
    setSelectedFilters([]);
    setCategoryFilter([]);
    setPriceRange([0, 1000]);
    setSizeFilter([]);
    setMaterialFilter("");
    // setCurrentPage(1);

    // Clear localStorage
    localStorage.removeItem("isZipper");
    localStorage.removeItem("isLockable");
    localStorage.removeItem("selectedFilters");
    localStorage.removeItem("categoryFilter");
    localStorage.removeItem("sizeFilter");
    localStorage.removeItem("materialFilter");

    // Fetch all products without any filters
    fetchAllProducts();
  };

  return (
    <>
      <MetaDescTag
        title={"Products | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="product-page">
        <div className="py-5 product-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 product-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">
                  Products
                </h1>
              </div>
              {allProducts?.length > 0 ? (
              <div className="product-page-body">
                <>
                  <button
                    className="myFilteration-Btn"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    <LuSettings2 className="filterICON" /> <span>Filter</span>
                  </button>
                  <div className="w-100">
                    <div className="d-grid gap-3 product-page-row">
                      {/* {loading ? (
                        <Loading />
                      ) : allProducts.length === 0 ? (
                        <p className="not-product-found">
                          Products are not found
                        </p>
                      ) : ( */}
                        {allProducts.map((product) => (
                          <Product key={product.pkProdId} product={product} />
                        ))}
                      {/* )} */}
                    </div>
                    <div className="mt-5 product-page-pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`pagination-disable-btn ${
                          currentPage === 1 ? "prev-button-disabled" : ""
                        }`}
                      >
                        Previous
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={isLastPage}
                        className={`pagination-next-btn ${
                          currentPage === totalPages
                            ? "next-button-disabled"
                            : ""
                        }`}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              </div>
              ) : (
                <p className="mb-0 text-center" style={{ fontSize: "40px" }}>
                  Products Not Found
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              Filters
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="filter-section">
              <div className="categories-checkbox">
                <p className="mb-2">Categories</p>
                {loadingFilters ? (
                  <Loading />
                ) : (
                  <div>
                    {filterType.length > 0 ? (
                      filterType.map((item, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item}
                            id={`flexCheckDefault-${index}`}
                            checked={selectedFilters.includes(item)}
                            onChange={handleFilterChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexCheckDefault-${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>No filters available</p>
                    )}
                  </div>
                )}
              </div>
              <div className="filters-radio">
                <p className="mb-2">Filters</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="filterOptions"
                    id="isZipper"
                    checked={isZipper === "1"}
                    onChange={handleZipperChange}
                  />
                  <label className="form-check-label" htmlFor="isZipper">
                    Zipper Jewellery Box
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="filterOptions"
                    id="isLockable"
                    checked={isLockable === "1"}
                    onChange={handleLockableChange}
                  />
                  <label className="form-check-label" htmlFor="isLockable">
                    Lockable Jewellery Box
                  </label>
                </div>
              </div>
              <div className="filter-price-range">
                <p className="mb-2">Price</p>
                <div className="price-input-container">
                  <div className="price-input">
                    <div className="price-field">
                      <span>Minimum Price</span>
                      <input
                        type="number"
                        className="min-input"
                        value={priceRange[0]}
                        onChange={(e) =>
                          setPriceRange([Number(e.target.value), priceRange[1]])
                        }
                        readOnly
                      />
                    </div>
                    <div className="price-field">
                      <span>Maximum Price</span>
                      <input
                        type="number"
                        className="max-input"
                        value={priceRange[1]}
                        onChange={(e) =>
                          setPriceRange([priceRange[0], Number(e.target.value)])
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="slider-container">
                    <div className="price-slider"></div>
                  </div>
                </div>
                <div className="range-input">
                  <input
                    type="range"
                    className="min-range"
                    min="0"
                    max="1000"
                    value={priceRange[0]}
                    step="1"
                    onChange={handlePriceChange}
                  />
                  <input
                    type="range"
                    className="max-range"
                    min="0"
                    max="1000"
                    value={priceRange[1]}
                    step="1"
                    onChange={handlePriceChange}
                  />
                </div>
              </div>
              <div className="size-checkbox">
                <p className="mb-2">Size</p>
                <div>
                  {sizeDataForCheckBox.map((size) => (
                    <div className="form-check" key={size.id}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={size.title}
                        id={size.id}
                        checked={sizeFilter.includes(size.title)}
                        onChange={handleSizeChange}
                      />
                      <label htmlFor={size.id} className="form-check-label">
                        {size.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="material-drop-down">
                <p className="mb-2">Material</p>
                <div>
                  {materialDataForBox.map((material) => (
                    <div className="form-check" key={material.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="material"
                        id={`material-${material.id}`}
                        value={material.title}
                        checked={materialFilter === material.title}
                        onChange={handleMaterialChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`material-${material.id}`}
                      >
                        {material.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer">
            <div className="clear-filter">
              <span onClick={clearFilters} className="trash-product-filter">
                <FaTrashAlt style={{ height: "13px" }} />
                Clear
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
