import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { blogs, blogDetail } from "../../data";
import MetaDescTag from "../../components/MetaDescTag";
import { MdArrowBackIos } from "react-icons/md";

const BlogDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  // Find the blog from both blogs and blogDetail by slug
  const blog = blogs.find((item) => item.slug === slug);
  const blogDetails = blogDetail.find((item) => item.slug === slug);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <MetaDescTag
        title={"Top Manufacturer | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to KARV Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the visionary Karishma, our journey began with a mission to redefine leather goods and accessories."
        }
      />
      {blog && (
        <section className="blog-detail-section">
          <div className="blog-detail-body-section py-5">
            <div className="our-container">
              <div className="inner-container">
                <button
                  onClick={handleBackClick}
                  className="back-btn-per-page mb-3"
                >
                  <MdArrowBackIos className="back-icon-per-page" />
                  Back
                </button>
                <div className="blog-detail-section-block">
                  <div className="blog-detail-section-block-img">
                    <img src={blog?.blogImg} alt="" />
                  </div>
                  <div className="blog-detail-section-block-content">
                    <div className="mb-5">
                      <h1 className="text-capitalize">{blog.blogTitle}</h1>
                      <p>{blog.blogDesc}</p>
                    </div>
                    {/* Conditionally render extra blog details */}
                    {blogDetails?.data && (
                      <div className="mb-5">
                        <div className="h4 main-title-for-blog">
                          {blogDetails.data}
                        </div>
                        <p>{blogDetails.dataBlogDesc}</p>
                      </div>
                    )}

                    {blogDetails?.dataOther && (
                      <div className="mb-5">
                        <div className="h4 main-title-for-blog">
                          {blogDetails.dataOther}
                        </div>
                        <p>{blogDetails.dataOtherBlogDesc}</p>
                      </div>
                    )}

                    {blogDetails?.questionHeading && (
                      <div className="h4 main-title-for-blog mb-3">
                        {blogDetails.questionHeading}
                      </div>
                    )}

                    {blogDetails?.list && (
                      <div className="mb-5">
                        <ul>
                          {blogDetails.list.map((item) => (
                            <li key={item.id} className="mb-3">
                              <div className="h6 main-title-for-blog">
                                {item.question}
                              </div>
                              <p className="">{item.answer}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {blogDetails?.subPara && <p>{blogDetails.subPara}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogDetail;

// import React from "react";
// import { useParams } from "react-router-dom";
// import { blogs } from "../../data";

// const BlogDetail = () => {
//   const { slug } = useParams();
//   const blog = blogs.find((item) => item.slug === slug);

//   console.log(blog)

//   return (
//     <>
//       {/* <MetaDescTag
//         title={blog.blogTitle + " | KARN Marketing Warfare LLP"}
//         description={blog.blogDesc}
//       /> */}
//       <section className="blog-detail-section">
//         <div className="blog-detail-body-section py-5">
//           <div className="our-container">
//             <div className="inner-container">
//               <div className="blog-detail-section-block">
//                 <div className="blog-detail-section-block-img">
//                   <img src={blog?.blogImg} alt="" />
//                 </div>
//                 <div className="blog-detail-section-block-content">
//                   <h1 className="text-capitalize">{blog.blogTitle}</h1>
//                   <p>{blog.blogDesc}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default BlogDetail;
