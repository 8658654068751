import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import Pagination from "../../components/Pagination";
import MetaDescTag from "../../components/MetaDescTag";
import { blogs } from "../../data";

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  // Sort blogs by id in descending order
  const sortedBlogs = blogs.sort((a, b) => b.id - a.id);

  const truncate = (str, n) => {
    return str.split(" ").splice(0, n).join(" ");
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const currentBlogs = sortedBlogs.slice(
    (currentPage - 1) * blogsPerPage,
    currentPage * blogsPerPage
  );

  return (
    <>
      <MetaDescTag
        title={"Blog | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to KARV Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the visionary Karishma, our journey began with a mission to redefine leather goods and accessories."
        }
      />
      <section className="blog-section">
        <div className=" blog-body-section py-5">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="mb-5">
                <h1>Blogs</h1>
              </div>
              <div className="d-grid gap-3 blog-section-body-row">
                {currentBlogs.map((item, id) => {
                  return (
                    <div className="blog-section-block" key={id}>
                      <Link
                        to={`/blog/${item.slug}`}
                        className="blog-section-block-img"
                      >
                        <img src={item.blogImg} alt="" />
                      </Link>
                      <div className="blog-section-block-content">
                        <Link
                          to={`/blog/${item.slug}`}
                          className="h5 text-capitalize"
                        >
                          {item.blogTitle}
                        </Link>
                        <p>{truncate(item.blogDesc, 14)} [...]</p>
                      </div>
                      <Link
                        to={`/blog/${item.slug}`}
                        className="blog-section-block-learn-more-link"
                      >
                        <span>Learn More</span>{" "}
                        <MdOutlineKeyboardDoubleArrowRight />
                      </Link>
                    </div>
                  );
                })}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;

// import React from "react";

// const Blog = () => {
//   return (
//     <>
//             {/* <MetaDescTag
//         title={"Products | Jewelry Boxes | KARV Accessories"}
//         description={
//           "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
//         }
//       /> */}
//       <section className="blog-page">
//         <div className="py-5 blog-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <div className="pb-5 blog-page-header">
//                 <h1 className="fs-1 fw-medium text-capitalize mb-2">Blog</h1>
//               </div>
//               <div className="blog-page-body">
//                 <div>
//                   <div className="d-grid gap-3 blog-page-row">
//                     {/* {filteredImages.map((item, index) => ( */}
//                       <div
//                         // key={item.id}
//                         className="text-decoration-none product-link d-flex justify-content-between flex-column p-2 blog-row-block"
//                       >
//                       </div>
//                     {/* ))} */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Blog;
