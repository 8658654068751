import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import MetaDescTag from "../../components/MetaDescTag";

const ContactUs = () => {
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    phoneNo: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response1 = await fetch(
          `${process.env.REACT_APP_API_URL}/contact/add`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response1.ok) {
          throw new Error("Network response was not ok for the first API call");
        }

        const response2 = await fetch(
          `${process.env.REACT_APP_API_URL}/contact/userEmail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName: formData.userName,
              userEmail: formData.userEmail,
              phoneNo: formData.phoneNo,
              message: formData.message,
            }),
          }
        );

        const response3 = await fetch(
          `${process.env.REACT_APP_API_URL}/contact/adminEmail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName: formData.userName,
              userEmail: formData.userEmail,
              phoneNo: formData.phoneNo,
              message: formData.message,
            }),
          }
        );

        setSubmitted(true);

        if (response1.ok && response2.ok && response3.ok) {
          toast.success("Your message has been sent successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });
          setFormData({
            userName: "",
            userEmail: "",
            phoneNo: "",
            message: "",
          });
          navigate("/");
          setErrors({});
        } else {
          toast.error("There was an error sending your message.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("Error: " + error.message, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.userName) {
      formIsValid = false;
      errors["userName"] = "Please enter your name";
    }

    if (!formData.phoneNo && !formData.userEmail) {
      formIsValid = false;
      errors["phoneNo"] = "Please enter either your phone number or email";
    } else if (formData.phoneNo && !/^\d{10}$/.test(formData.phoneNo)) {
      formIsValid = false;
      errors["phoneNo"] = "Please enter a valid 10 digit phone number";
    } else if (formData.userEmail && !/\S+@\S+\.\S+/.test(formData.userEmail)) {
      formIsValid = false;
      errors["userEmail"] = "Please enter a valid email address";
    }

    setErrors(errors);
    return formIsValid;
  };

  return (
    <>
      <MetaDescTag
        title={"Contact Us | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="contact-page">
        <div className="contact-page-block">
          <div className="our-container">
            <div className="inner-container">
              <div className="contact-page-body">
                <div className="contact-page-main-block">
                  <div className="contact-page-heading">
                    <h1>Send Us A Message</h1>
                    <p className="mb-0">
                      Email us at karvaccessories@gmail.com, or contact on
                      +919315155880
                    </p>
                    <p>
                      Our customer care team will revert back to you within 24
                      business hours.
                    </p>
                  </div>
                  <div className="error-messages-show">
                    {errors.userName && (
                      <p className="text-center text-danger">
                        {errors.userName}
                      </p>
                    )}

                    {errors.phoneNo && (
                      <p className="text-center text-danger">
                        {errors.phoneNo}
                      </p>
                    )}

                    {errors.userEmail && (
                      <p className="text-center text-danger">
                        {errors.userEmail}
                      </p>
                    )}

                    {submitted && (
                      <p className="text-center text-success mt-3">
                        We Get Your Response, Our Team Will Contact You As Soon
                        As Possible!
                      </p>
                    )}
                  </div>
                  <div className="contact-page-main-row-block">
                    <div className="contact-page-iframe">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1120.9233007001344!2d73.03091999003746!3d19.09354498673255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1a8f1f81c29%3A0x7ad553b109514985!2sGami%20Industrial%20Park%20-%20By%20Gami%20Group!5e0!3m2!1sen!2sin!4v1724305679190!5m2!1sen!2sin"
                        width="100%"
                        height="557"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                    <div className="contact-sub-main-block">
                      <div className="contact-page-form">
                        <form className="pt-0 pb-5" onSubmit={handleSubmit}>
                          <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                            <label
                              htmlFor="name"
                              className="text-capitalize mb-1"
                            >
                              Your Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="userName"
                              value={formData.userName}
                              onChange={handleChange}
                              autoComplete="off"
                              className="px-2 rounded-0"
                            />
                          </div>
                          <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                            <label
                              htmlFor="number"
                              className="text-capitalize mb-1"
                            >
                              Your Number <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="number"
                              name="phoneNo"
                              value={formData.phoneNo}
                              // onChange={handleChange}
                              // onChange={(e) => {
                              //   // Accept only digits and restrict to 10 characters
                              //   const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                              //   if (value.length <= 10) {
                              //     handleChange({
                              //       target: { name: "phoneNo", value },
                              //     });
                              //   }
                              // }}
                              onChange={(e) => {
                                e.target.value = e.target.value
                                  .slice(0, 10)
                                  .replace(/\D/g, "");
                                handleChange(e); // Ensure this triggers the state update
                              }}
                              autoComplete="off"
                              className="px-2 rounded-0"
                              maxLength={10}
                            />
                          </div>
                          <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                            <label
                              htmlFor="email"
                              className="text-capitalize mb-1"
                            >
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="userEmail"
                              value={formData.userEmail}
                              onChange={handleChange}
                              autoComplete="off"
                              className="px-2 rounded-0"
                            />
                          </div>
                          <div className="d-flex align-align-items-center flex-column mb-4 contact-form-group">
                            <label
                              htmlFor="message"
                              className="text-capitalize mb-1"
                            >
                              Your Message
                            </label>
                            <textarea
                              name="message"
                              id="message"
                              rows={"7"}
                              value={formData.message}
                              onChange={handleChange}
                              autoComplete="off"
                              className="p-2 rounded-0"
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="text-capitalize contact-form-button"
                          >
                            Send
                          </button>
                        </form>
                      </div>
                      <div className="contact-page-details">
                        <div className="contact-page-details-block">
                          <div className="contact-header-contact-us-block mb-1">
                            <MdEmail
                              className="contact-header-contact-us-icon"
                              style={{ fontSize: "24px" }}
                            />
                          </div>
                          <Link
                            to={"mailto:karvaccessories@gmail.com"}
                            className="contact-page-details-block-link mb-0"
                          >
                            karvaccessories@gmail.com
                          </Link>
                        </div>
                        <div className="contact-page-details-block">
                          <div className="contact-header-contact-us-block mb-1">
                            <FaPhoneAlt
                              className="contact-header-contact-us-icon"
                              style={{ fontSize: "23px" }}
                            />
                          </div>
                          <Link
                            to={"tel:9315155880"}
                            className="contact-page-details-block-link mb-0"
                          >
                            9315155880
                          </Link>
                        </div>
                        <div className="contact-page-details-block">
                          <div className="contact-header-contact-us-block mb-1">
                            <FaLocationDot
                              className="contact-header-contact-us-icon"
                              style={{ fontSize: "24px" }}
                            />
                          </div>
                          <Link
                            to={"https://maps.app.goo.gl/q4iceQj5AV6JZoTN9"}
                            className="contact-page-details-block-link mb-0"
                          >
                            5th Floor, Gami Industrial Park, B-40, MIDC
                            Industrial Area, Pawne, Navi Mumbai, Maharashtra
                            400705
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
